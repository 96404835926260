<template>
  <div style="width: 100%;height: 100%;background: #000;">
    <global-header style="height: 60px;"></global-header>
    <div class="contentPanel">
      <router-view></router-view>
    </div>

    <global-footer style="height: 179px;"></global-footer>
  </div>
  <!-- <div>
        <global-header></global-header>
        <global-content></global-content>
        <global-footer></global-footer>
    </div> -->
</template>

<script>
import GlobalHeader from "./GlobalHeader";
import GlobalContent from "./GlobalContent";
import GlobalFooter from "./GlobalFooter";
import { getUserInfo, getStore } from "@/utils/storage";

export default {
  name: "GlobalLyout",
  components: {
    GlobalHeader,
    GlobalContent,
    GlobalFooter,
  },
  data() {
    return {
      stylesheetName: "",
      channelLogin: false,
    };
  },
  mounted() {
    // this.channelLogin = getStore(getStore('hostname') + "_channelLogin") === '01'
    // this.userInfo = getUserInfo() || this.userInfo;
    // this.stylesheetName = this.userInfo.stylesheetName || 'default';
    // this.banner = '../../assets/' + this.stylesheetName + '/home/banner.png';
  },
};
</script>
<style lang="scss" scoped>

</style>
<style lang="less">
#globalLayout {
  min-height: 100%;
  background: #f3f2fb;

  .table-page-search-wrapper {
    .table-page-search-submitButtons {
      display: block;
      margin-bottom: 24px;
      white-space: nowrap;
    }
  }

  form .ant-select {
    min-width: 120px;
  }
}
</style>
<style lang="less" scoped>
.contentPanel {
  // height: calc(100% - 10px);
  background-image: url('~@/assets/images/back.png');
  background-position: 50% center;
}

.el-main {
  padding: 0;
}

.el-footer {
  padding: 0;
}
</style>