<template>
  <div>
    <div id="pc2" style="background-color: #101010;position: relative;bottom: 0;">
      <div style="padding-top:35px;">
        <el-row :gutter="24" style="width: 71%; margin: 0 auto">
        <el-col :span="12" style="margin: 0 auto;">
          <div style="font-size: 22px; color: #d3bc93">
            {{ businname }}
          </div>
          <div style="font-size: 14px; color: #d3bc93">
            <span>营业执照：</span><span>91130113MA07MCAN8D</span>
          </div>
          <div style="font-size: 14px; color: #d3bc93">
            <span>备案号码：</span><span>{{ filing }}</span>
          </div>
          <div style="font-size: 14px; color: #d3bc93">
            <span>地址：</span><span>河北省石家庄市裕华区裕华东路133号方北大厦A座</span>
          </div>
        </el-col>
        <el-col :span="6" style="margin: 0 auto;">
          <div style="font-size: 22px; color: #d3bc93">联系我们</div>
          <div style="font-size: 14px; color: #d3bc93"><span>电话：</span><span>{{ phone }}</span></div>
          <div style="font-size: 14px; color: #d3bc93;display: flex;align-items: center;">
            <span>邮箱：</span>
            <span>{{ email }}</span>
          </div>
          <div style="font-size: 14px; color: #d3bc93"><span>Q Q：</span><span>29750539</span></div>
        </el-col>
        <el-col :span="6" style="margin: 0 auto;text-align: right;"> 
          <img
            src="../../assets/官网切图/022017515281_0二维码.png"
            alt=""
            style="width: 100px"
          />
        </el-col>
      </el-row>
      <div style="text-align: center; color: #d3bc93; font-size: 12px;margin-top: 0.2rem;">
          Copyright @ 2016-2023 Qianhe Tech All Rights Reserved
      </div>
      </div>
    </div>
    <div id="mobile2"  style="width:100%;height:50px;">
        <div style="background-color: black;z-index: 1;width: 100%;">
        <div class="yd-son1">
            <span style="width: 60%;">
                <div style="color: #D3BC93;font-size: 14px;"> {{ businname }}</div>
                <div style="color: #D3BC93;font-size: 12px;"><span>营业执照：</span> <span>91130113MA07MCAN8D</span></div>
                <div style="color: #D3BC93;font-size: 12px;"><span>备案号码：</span> <span>{{ filing }}</span></div>
                <div style="color: #D3BC93;font-size: 12px;"><span>地址：</span><span>河北省石家庄市裕华区建设南大街215号万达写字楼F区B座</span></div>
            </span>
            <span style="width: 60%;text-align: right;">
                <img src="../../assets/官网切图/022017515281_0二维码.png" alt="" style="width:120px">
            </span>
        </div>
        <div style="text-align: center; color: #d3bc93; font-size: 12px;margin-top: 0.1rem;">
          Copyright @ 2016-2023 Qianhe Tech All Rights Reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/utils/storage";
import { getAction,postAction } from "@/api/manage";
export default {
  name: "GlobalFooter",
  data() {
    return {
      businname:'',
      filing :'',
      email:'',
      phone:'',
      active: "home",
      stylesheetName: "",
    };
  },
  methods: {
    getlist(){
      postAction('/api/option/query',{
        key:'web_subtitle',
        // key:'web_ipc_no',
        // key:'web_mater',
      },{'Content-Type':'application/x-www-form-urlencoded'}).then((res)=>{
        // console.log(res,'11')
        this.businname = res.value
      })
      postAction('/api/option/query',{    
        key:'web_ipc_no',
      },{'Content-Type':'application/x-www-form-urlencoded'}).then((res)=>{
        // console.log(res,'22')
        this.filing = res.value
      })
      postAction('/api/option/query',{
        key:'web_mater_mobile',
      },{'Content-Type':'application/x-www-form-urlencoded'}).then((res)=>{
        // console.log(res,'33')
        this.phone = res.value
      })
      postAction('/api/option/query',{
        key:'web_mater_email',
      },{'Content-Type':'application/x-www-form-urlencoded'}).then((res)=>{
        // console.log(res,'44')
        this.email = res.value
      })
    },
  },
  mounted() {
    this.getlist()
    // let str = window.navigator.userAgent;
    // if (str.toLowerCase().indexOf("mobile") == -1) {
    //   console.log(document.getElementById("pc2"),'pc')
    //   document.getElementById("pc2").style.display = "block";
    //   document.getElementById("mobile2").style.display = "none";
    // } else {
    //   document.getElementById("mobile2").style.display = "block";
    //   document.getElementById("pc2").style.display = "none";
    // }
  },
};
</script>

<style lang="less">
@import url(../../assets/font/font/iconfont.css);
@import url(../../assets/font/font_n1/iconfont.css);
@import url(../../assets/font/font_n2/iconfont.css);
</style>
<style lang="less" scoped>
#pc2{
  display: none;
}
#mobile2{
 display: none;
}
@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc2 {
    display: none;
  }

  #mobile2 {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc2 {
    display: block;
   // background-image: url(../../assets/官网切图/官网-背景.png)
  }

  #mobile2 {
    display: none;
  }
}
.el-footer{
  padding: 0;
}
::v-deep .el-footer {
  background-color: #1990ff;
}
.nav_logo {
  color: #1990ff;
}
.footer {
  width: 100%;
  position: relative;
}
.footerPanel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
  text-align: center;
  padding: 10px;
}

.footerPanel div {
  width: 50%;
  color: #4a6781;
  height: 30px;
  line-height: 30px;
  font-size: 12.8px;
  i {
    width: 12px;
    padding-right: 5px;
  }
}
.yd-son1{
width: 90%;
margin: 0 auto;
display: flex;
padding-top: 0.2rem;
}
.footerPanel div {
  &.active {
    color: #1990ff;
  }
}
//  .default {
//      .footerPanel div {
//          &.active {
//              color: #2a8ff7;
//          }
//     }
// }

.red {
  .footerPanel div {
    &.active {
      color: #1990ff;
    }
  }
}
</style>