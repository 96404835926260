<template>
    <div>
        <router-view></router-view> 
    </div>    
</template>

<script>
    export default {
        name: 'GlobalContent',
        data() {
            return {
                menus: []
            }
        },
        components: {
        }
    }
</script>

<style lang="less" scoped>
    // .content {
    //     margin: 30px 40px;
    // }
    .el-main{
        padding: 0;
    }
</style>