<template>
  <div>
    <el-header id="header" :class="{ sticky: isSticky }">
      <div id="pc">
        <div style="width: 100%; overflow-x: auto">
          <div style="display: flex; width: 60%; margin: 0 auto">
            <div style="
                      padding-top: 0.05rem;
                      text-align: right;
                      margin-right: 0.5rem;
                      width: 2rem;
                    ">
              <img src="../../assets/官网切图/022017450701_0logo 原版.png" alt="" style=" ;" />
            </div>
            <div style="width: 1000px;">
              <el-menu class="menuList" :default-active="$router.path" mode="horizontal" text-color="#212529"
                active-text-color="#8d5858" style="width: 1000px;" @select="goroute">
                <el-menu-item class="menuItem" style="
                          height: 60px;
                          line-height: 60px;
                          font-size: 15px;
                          color: aliceblue;
                        " v-for="(item, index) in menulist" :key="index" :index="index.toString()">{{ item.value
                        }}</el-menu-item>
                <!-- <el-submenu
                  v-if="moreArr.length > 0"
                  class="menuItem"
                  
                >
                  <template slot="title">更多</template>
                  <el-menu-item
                    v-for="i in moreArr"
                    :key="i.index"
                    :index="i.index"
                    >{{ i.value }}</el-menu-item
                  >
                </el-submenu> -->
              </el-menu>
            </div>
          </div>
        </div>
      </div>
      <div id="mobiel">
        <div class="yd-title">
          <span class="yd-logo1">
            <div id="yd-logo2" @click="clickslice">
              <img src="../../assets/APP 首页_slices/菜单.png" alt="" />
            </div>
            <div id="navigation">
              <div class="menu" v-for="(item, index) in menulist" :key="index" @click="goroute(index)">
                {{ item.value }}
              </div>
            </div>
          </span>
          <div>
            <img src="../../assets/APP 首页_slices/组 5 拷贝_waifu2x_art_noise3.webp" alt="" style="max-width: 100%;
                      max-height: 100%;
                      margin: 0.1rem;
                      width: 2rem;
                    " />
          </div>

          <div style="width: 0.5rem; height: 0.5rem"></div>
        </div>
      </div>
    </el-header>
  </div>
</template>
<script>
import {
  setLocalStore,
  getLocalStore,
  setUserInfo,
  getUserInfo,
  setCurrentCompany,
  setAccessToken,
} from "@/utils/storage";
import { postAction } from "@/api/manage";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isSticky: "isSticky",
    }),
  },
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "",
      param: false,
      equipment: "mobiel",
      currentTabIndex: 0,
      newArr: [],
      existing: 0,
      moreArr: [],
      isb: false,
      menulist: [
        {
          value: "首页",
          index: 0,
        },
        {
          value: "公司简介",
          index: 1,
        },
        {
          value: "新闻中心",
          index: 2,
        },
        {
          value: "产品中心",
          index: 3,
        },
        {
          value: "服务案例",
          index: 4,
        },
        {
          value: "公司荣誉",
          index: 5,
        },
        {
          value: "招贤纳士",
          index: 6,
        },
        {
          value: "联系我们",
          index: 7,
          // route:'/contactus'
        },
      ],
    };
  },

  activated() { },
  // computed: {
  //   activeinHouseList: function () {
  //     return this.menuList.filter((item) => {
  //       return item.text != "更多";
  //     });
  //   },
  //   moreList: function () {
  //     return this.menuList.filter((item) => {
  //       return item.text == "更多";
  //     });
  //   },
  // },
  methods: {
    clickslice() {
      this.param = !this.param;
      // console.log(this.param, "454545");
      if (this.param == true) {
        document.getElementById("navigation").style.display = "block";
      } else {
        document.getElementById("navigation").style.display = "none";
      }
    },
    // sum(arr) {
    //   return arr.reduce(function (prev, curr, idx, arr) {
    //     return prev + curr;
    //   });
    // },
    // isbeyond(widthArr) {
    //   let menu = document.getElementsByClassName("menuList");
    //   console.log(this.sum(widthArr),'787878')
    //   console.log(menu,'787878')
    //   if (this.sum(widthArr) > menu[0].clientWidth) {
    //     this.isb = true;
    //     let newArr = this.menulist.slice(0, this.menulist.length - 1);
    //     let newWidthArr = widthArr.slice(0, this.menulist.length - 1);
    //     this.menulist = newArr;
    //     this.isbeyond(newWidthArr);
    //   } else {
    //     if (this.isb) {
    //       let newArr = this.menulist.slice(0, this.menulist.length - 1);
    //       let newWidthArr = widthArr.slice(0, this.menulist.length - 1);
    //       this.menulist = newArr;
    //       this.existing = newWidthArr.length;
    //     }
    //   }
    // },
    goroute(value) {
      // console.log(value, "898");
      this.activeIndex2 = value;

      if (value == 7) {
        this.$router.push({
          path: "/contactus",
        });
      } else if (value == 0) {
        this.$router.push({
          path: "/index",
        });
      } else if (value == 6) {
        this.$router.push({
          path: "/recruit",
        });
      } else if (value == 4) {
        this.$router.push({
          path: "/case",
        });
      } else if (value == 3) {
        this.$router.push({
          path: "/products",
        });
      } else if (value == 5) {
        this.$router.push({
          path: "/honor",
        });
      } else if (value == 1) {
        this.$router.push({
          path: "/bussintroduction",
        });
      } else if (value == 2) {
        this.$router.push({
          path: "/newcenter",
        });
      }

      this.param = false;
      document.getElementById("navigation").style.display = "none";
    },

  },

  created() { },
  mounted() {

    let menu = document.getElementsByClassName("menuList");
    // console.log('787878')
    // console.log(menu,'78hjhkjhk7878')
    // let newArr = JSON.parse(JSON.stringify(this.menulist));
    // let menuitem = document.getElementsByClassName("menuItem");
    // let widthArr = [];
    // for (let i = 0; i < menuitem.length; i++) {
    //   widthArr.push(menuitem[i].clientWidth);
    // }
    // this.isbeyond(widthArr);
    // //更多的数组
    // let arr = [];
    // if (this.existing != 0) {
    //   arr = newArr.splice(this.existing, newArr.length - 1);
    // }
    // this.moreArr = arr;
  },
};
</script>
<style lang="less" scoped>
#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  body {
    background-color: #111421 !important;
    //background-image: url(../../assets/官网切图/官网-背景.png);
  }

  #pc {
    display: block;
    background-image: url(../../assets/官网切图/官网-背景.png);
  }

  #mobiel {
    display: none;
  }
}

.el-menu {}

.el-menu--horizontal>.el-submenu {
  float: none;
}

.menuitem1 {
  color: aliceblue;
  height: 60px;
  line-height: 60px;
  font-size: 15px;
}

.el-dropdown-menu__item,
.el-menu-item {
  padding: 0 0.15rem;
}

.el-menu-item.is-active {
  background-color: #161c34 !important;
  color: #ffffff;
}

.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: #161c34;
}

.el-menu--horizontal>.el-menu-item {
  border-bottom: none;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
  background-color: #161c34;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
  background-color: #161c34;
}

.el-header {
  padding: 0;
}

.menu {
  color: #ffffff;
  font-size: 12px;
  // margin-top: 0.1rem;
  padding: 0.1rem 0;
  background-color: #161c34;
  width: 1rem;
  text-align: center;
}

.yd-logo1 {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #202b52;
  line-height: 0.5rem;
  text-align: center;
  // position: absolute;
  // left: 0.2rem;
  // top: 0.2rem;
}

.yd-title {
  /* margin-left: -1.5rem; */
  position: relative;
  display: flex;
  justify-content: space-around;
  background-color: #000000;
  align-items: center;
}

.left {
  color: #ffffff;
}

.right {
  color: #ffffff;
}

// .mobiel {
//   display: none;
// }
// .pc {
//   display: block;
// }
// @media screen and (max-width: 768px) {
//   .mobiel {
//     display: block;
//   }
//   .pc {
//     display: none;
//   }
// }
#navigation {
  display: none;
}

.header {
  background-image: url(../../assets/官网切图/导航背景.png);
}

.el-menu {
  background-color: transparent;
}

.menu-item {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
}


.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  transition: top 0.3s ease-out;
  /* 添加过渡效果，使top属性的变化平滑过渡 */
}
</style>